<template>
  <div>
    <van-sticky>
      <title-bar2 :title="courseInfo && courseInfo.CourseName" />
    </van-sticky>
    <div class="content-box">
      <div class="content-video-box">
        <video-player ref="video"></video-player>
      </div>
      <div class="content-info-box">
        <div class="info_row">
          <div class="info_title" v-if="courseInfo">{{ courseInfo.CourseName }}</div>
          <div v-if="courseInfo" class="info_price_box">
            <span class="selling_price">￥{{ courseInfo.CourseSellingPrice.toFixed(2) }}</span>
            <span class="original_price">原价￥{{ courseInfo.CourseOriginalPrice.toFixed(2) }}</span>
          </div>
        </div>
        <div class="info_row">
          <span class="info_desc">学习期限：</span>
          <span class="info_text" v-if="courseInfo">{{ courseInfo.CoursePeriodOfStudy }}</span>
        </div>
        <div class="info_row">
          <span class="info_desc">课程服务：</span>
          <img class="info_img" :src="require('@/assets/images/comm/tick.png')" />
          <span class="info_text">在线播放</span>
          <img class="info_img" :src="require('@/assets/images/comm/tick.png')" />
          <span class="info_text">电子讲义</span>
          <img class="info_img" :src="require('@/assets/images/comm/tick.png')" />
          <span class="info_text">打卡督学</span>
        </div>
        <div class="info_row">
          <span class="info_desc">主讲老师：</span>
          <span class="info_text" v-if="courseInfo">{{ courseInfo.CourseTeacherList.join(' ') }}</span>
          <span class="info_desc info_margin_left">开课时间：</span>
          <span class="info_text">报名即学</span>
        </div>
        <div class="info_row">
          <span class="info_desc">课时数量：</span>
          <span class="info_text" v-if="courseInfo">{{ courseInfo.CourseLessonCount }}</span>
        </div>
      </div>
      <div class="content-tabs-box">
        <van-tabs v-model="activeTab">
          <van-tab title="课程介绍" name="1">
            <img :src="courseIntroImage" class="introl-image" />
          </van-tab>
          <van-tab title="课程课表" name="2">
            <course-lesson-list v-if="courseInfo" :courseType="courseInfo.CourseType" :active="activeLesson" :unlocked="unlocked" :list="courseInfo.CourseType == 0 ? lessonList : courseGroupList" @play-click="onPlayClick" @unlock-click="onUnlockClick"></course-lesson-list>
          </van-tab>
          <van-tab title="师资介绍" name="3">
            <div v-if="courseInfo" class="teacher-text">{{ courseInfo.CourseTeacherDescribe }}</div>
          </van-tab>
          <van-tab title="课程讲义" name="4">
            <course-lecture-list v-if="courseInfo" :courseGuid="courseGuid" :courseType="courseInfo.CourseType" :unlocked="unlocked" :list="courseInfo.CourseType == 0 ? lectureList : courseGroupList" @unlock-click="onUnlockClick"></course-lecture-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="content-bar-box">
      <van-goods-action>
        <van-goods-action-icon icon="wap-home-o" text="首页" @click="onHome" />
        <van-goods-action-icon icon="chat-o" text="客服" @click="onContact" />
        <van-goods-action-button v-if="!unlocked" type="danger" text="立即购买" @click="onPay" />
        <van-goods-action-button type="warning" :text="unlocked ? '立即学习' : '免费试看'" @click="onSee" />
      </van-goods-action>
    </div>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import VideoPlayer from "@/components/MT/VideoPlayer";
import CourseLessonList from "@/components/MT/CourseLessonList";
import CourseLectureList from "@/components/MT/CourseLectureList";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    TitleBar2,
    VideoPlayer,
    CourseLessonList,
    CourseLectureList,
  },
  props: {
  },
  data() {
    return {
      unlocked: null,
      courseGuid: null,
      courseInfo: null,
      activeTab: '1',
      activeLesson: null,
      lessonList: [],
      lectureList: [],
      courseGroupList: [],
    };
  },
  computed: {
    courseIntroImage() {
      if (this.courseGuid) {
        return `${Config.course.introImage}?CourseGuid=${this.courseGuid}`;
      }
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.courseGuid = this.$route.params.courseGuid;
    },
    //获取是否购买课程
    getCoursePurchase() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.purchased, model)
        .then((res) => {
          this.unlocked = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取课程信息
    getCourseInfo() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.courseInfo, model)
        .then((res) => {
          this.courseInfo = res.data.Data;
          //设置标题
          window.document.title = this.courseInfo.CourseName;
          //添加访问记录
          this.addVisitProductRecord();
          //获取课时、讲义列表
          if (this.courseInfo.CourseType == 0) {
            this.getCourseLessonList();
            this.getCourseLectureList();
          } else if (this.courseInfo.CourseType == 1) {
            this.getCourseGroupList();
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取课程组合列表
    getCourseGroupList() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.groupList, model)
        .then((res) => {
          this.courseGroupList = res.data.Data.List;
          if (this.courseGroupList.length > 0 && this.courseGroupList[0].CourseLessonList.length > 0 && this.courseGroupList[0].CourseLessonList[0].LessonFreeVideo) {
            this.activeLesson = this.courseGroupList[0].CourseLessonList[0];
            this.playVideo();
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取课时列表
    getCourseLessonList() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.lessonList, model)
        .then((res) => {
          this.lessonList = res.data.Data.List;
          if (this.lessonList.length > 0 && this.lessonList[0].LessonFreeVideo) {
            this.activeLesson = this.lessonList[0];
            this.playVideo();
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取讲义列表
    getCourseLectureList() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.lectureList, model)
        .then((res) => {
          this.lectureList = res.data.Data.List;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //初始化视频
    initVideo() {
      this.$refs.video.init(null, null);
    },
    //播放视频
    playVideo() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var url = `${Config.course.lessonVideo}?CourseGuid=${this.courseGuid}&LessonGuid=${this.activeLesson.LessonGuid}&Token=${this.$store.state.token}`;
      console.log('播放地址', url);
      this.$refs.video.replay(url);
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord(this.courseInfo.CourseName);
    },
    //首页
    onHome() {
      this.$router.push({ name: "Home" });
    },
    //点击在线支付
    onPay() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "SubmitCourseOrder",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            courseGuid: this.courseGuid
          }
        });
      }
    },
    //联系客服
    onContact() {
      this.$router.push({ name: "Contact" });
    },
    //免费试看
    onSee() {
      window.location.href = Manager.goCourseVideoUrl(this.$store.state.proxyCode, this.courseGuid, this.$store.state.token);
    },
    //点击播放
    onPlayClick(item) {
      if (!this.unlocked && item.LessonFreeVideo) {
        this.activeLesson = item;
        this.playVideo();
      } else {
        window.location.href = Manager.goCourseVideoUrl(this.$store.state.proxyCode, this.courseGuid, this.$store.state.token);
      }
    },
    //点击解锁
    onUnlockClick(item) {
      this.onPay();
    },
  },
  mounted() {
    this.initVideo();
    this.getRoutParams();
    this.getCoursePurchase();
    this.getCourseInfo();
  }
};
</script>
<style lang="less" scoped>
.content-box {
  padding: 0 10px;
}

.content-video-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdfe6;
}

.content-info-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  padding: 10px;
  margin-top: 10px;

  .info_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
  }

  .info_margin_left {
    margin-left: 30px;
  }

  .info_img {
    width: 15px;
    padding-right: 5px;
  }

  .info_title {
    font-size: 16px;
    color: black;
  }

  .info_desc {
    font-size: 14px;
    color: gray;
  }

  .info_text {
    font-size: 14px;
    color: black;
    margin-right: 10px;
  }

  .info_price_box {
    margin-left: auto;

    .selling_price {
      color: #ff6602;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      justify-content: flex-end;
    }

    .original_price {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
      color: gray;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
}

.content-tabs-box {
  position: relative;
  margin: 20px 0 10px 0;
  background-color: white;

  .free {
    position: absolute;
    top: 0px;
    left: 145px;
    z-index: 100;
    background-color: red;
    color: white;
    font-size: 10px;
    padding: 1px 8px;
    border-radius: 20px;
  }

  .introl-image {
    width: 100%;
    margin-top: 10px;
  }

  .teacher-text {
    color: #555;
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 10px;
  }
}

.content-bar-box {
  height: 50px;
}
</style>