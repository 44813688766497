<template>
  <div class="course-lecture-item">
    <span class="lecture-name">{{ lectureName }}</span>
    <a v-if="unlocked" :href="downSchoolCourseLectureFileUrl">
      <van-button hairline type="primary" size="small" class="lecture-down">下载</van-button>
    </a>
    <van-button v-else hairline type="primary" size="small" class="lecture-down" @click="onUnlockClick">下载</van-button>
  </div>
</template>

<script>
import Config from "@/api/service";
export default {
  name: "CourseLectureList",
  components: {
  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    courseGuid: {
      type: String,
      default: null,
    },
    lectureGuid: {
      type: String,
      default: null,
    },
    lectureName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    downSchoolCourseLectureFileUrl() {
      return `${Config.course.lectureFile}?CourseGuid=${this.courseGuid}&LectureGuid=${this.lectureGuid}&Token=${this.$store.state.token}`;
    },
  },
  methods: {
    onUnlockClick(item) {
      this.$emit("unlock-click", item);
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="less">
.course-lecture-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;

  .lecture-name {
    flex: 1;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .lecture-down {
    width: 50px;
    height: 28px;
    margin-left: 10px;
  }
}
</style>