<template>
  <div>
    <template v-if="courseType == 0">
      <el-table class="course-lecture" border :data="list" size="small">
        <el-table-column type="index" width="50" label="序号" align="center"> </el-table-column>
        <el-table-column prop="LectureName" label="文件名称"></el-table-column>
        <el-table-column label="下载文件" align="center" width="80">
          <template slot-scope="scope">
            <el-link v-if="unlocked" class="down-link" type="primary" :href="downSchoolCourseLectureFileUrl(scope.row)">下载</el-link>
            <el-button v-else class="el-icon-lock" size="mini" circle @click="onUnlockClick"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else-if="courseType == 1">
      <div v-for="courseInfo in list">
        <div class="course-name">{{ courseInfo.CourseName }}</div>
        <el-table class="course-lecture" border :data="courseInfo.CourseLectureList" size="small">
          <el-table-column type="index" width="50" label="序号" align="center"> </el-table-column>
          <el-table-column prop="LectureName" label="文件名称"></el-table-column>
          <el-table-column label="下载文件" align="center" width="80">
            <template slot-scope="scope">
              <el-link v-if="unlocked" class="down-link" type="primary" :href="downSchoolCourseLectureFileUrl(scope.row)">下载</el-link>
              <el-button v-else class="el-icon-lock" size="mini" circle @click="onUnlockClick"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </div>
</template>

<script>
import Config from "@/api/service";
export default {
  name: "CourseLectureTable",
  components: {
  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    courseGuid: {
      type: String,
      default: null,
    },
    courseType: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    downSchoolCourseLectureFileUrl() {
      return function (row) {
        return `${Config.course.lectureFile}?CourseGuid=${this.courseGuid}&LectureGuid=${row.LectureGuid}&Token=${this.$store.state.token}`;
      }
    },
  },
  methods: {
    onUnlockClick(item) {
      this.$emit("unlock-click", item);
    },
  },
  mounted() { },
};
</script>

<style scoped lang="less">
.course-name {
  font-size: 14px;
  margin: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-lecture {
  margin-bottom: 40px;
}

.down-link {
  font-size: 12px;
}
</style>