<template>
  <div>
    <div class="content-breadcrumb" v-if="courseInfo">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ name: 'Course' }">视频网课</el-breadcrumb-item>
        <el-breadcrumb-item>课程详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content-top">
      <div class="content-video-box">
        <span v-if="courseInfo" class="content-title">{{ courseInfo.CourseName }}</span>
        <video-player ref="video" class="content-video"></video-player>
      </div>
      <el-tabs type="border-card" class="content-right-tabs">
        <el-tab-pane label="课程课表">
          <course-lesson-list v-if="courseInfo" :courseType="courseInfo.CourseType" :active="activeLesson" :unlocked="unlocked" :list="courseInfo.CourseType == 0 ? lessonList : courseGroupList" @play-click="onPlayClick" @unlock-click="onUnlockClick"></course-lesson-list>
        </el-tab-pane>
        <el-tab-pane label="师资介绍">
          <div v-if="courseInfo" class="course-teacher-text" style="padding: 10px;">{{ courseInfo.CourseTeacherDescribe }}</div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="content-bottom">
      <div class="box-bottom_left">
        <div class="info-box">
          <div class="info_row">
            <div class="info_title" v-if="courseInfo">{{ courseInfo.CourseName }}</div>
            <div v-if="courseInfo" class="info_price_box">
              <span class="selling-price">￥{{ courseInfo.CourseSellingPrice.toFixed(2) }}</span>
              <span class="original-price">原价￥{{ courseInfo.CourseOriginalPrice.toFixed(2) }}</span>
            </div>
          </div>
          <div class="info_row">
            <span class="info_desc">学习期限：</span>
            <span class="info_text" v-if="courseInfo">{{ courseInfo.CoursePeriodOfStudy }}</span>
          </div>
          <div class="info_row">
            <span class="info_desc">课程服务：</span>
            <img class="info_img" :src="require('@/assets/images/comm/tick.png')" />
            <span class="info_text">在线播放</span>
            <img class="info_img" :src="require('@/assets/images/comm/tick.png')" />
            <span class="info_text">电子讲义</span>
            <img class="info_img" :src="require('@/assets/images/comm/tick.png')" />
            <span class="info_text">打卡督学</span>
          </div>
          <div class="info_row">
            <span class="info_desc">主讲老师：</span>
            <span class="info_text" v-if="courseInfo">{{ courseInfo.CourseTeacherList.join(' ') }}</span>
            <span class="info_desc info_margin_left">开课时间：</span>
            <span class="info_text">报名即学</span>
          </div>
          <div class="info_row">
            <span class="info_desc">课时数量：</span>
            <span class="info_text" v-if="courseInfo">{{ courseInfo.CourseLessonCount }}</span>
            <div class="info_button_box">
              <el-button v-if="!unlocked" type="primary" icon="el-icon-shopping-cart-full" @click="onPay">立即购买</el-button>
              <el-button type="warning" icon="el-icon-caret-right" @click="onSee">{{ unlocked ? '立即学习' : '免费试看' }}</el-button>
              <el-button type="primary" plain icon="el-icon-service" @click="onContact">在线咨询</el-button>
            </div>
          </div>
        </div>
        <div class="tabs-box">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="课程介绍" name="1">
              <img :src="courseIntroImage" class="course-introl-image" />
            </el-tab-pane>
            <el-tab-pane label="课程课表" name="2">
              <course-lesson-list v-if="courseInfo" :courseType="courseInfo.CourseType" :active="activeLesson" :unlocked="unlocked" :list="courseInfo.CourseType == 0 ? lessonList : courseGroupList" @play-click="onPlayClick" @unlock-click="onUnlockClick"></course-lesson-list>
            </el-tab-pane>
            <el-tab-pane label="师资介绍" name="3">
              <div v-if="courseInfo" class="course-teacher-text">{{ courseInfo.CourseTeacherDescribe }}</div>
            </el-tab-pane>
            <el-tab-pane label="课程讲义" name="4">
              <course-lecture-table v-if="courseInfo" :courseGuid="courseGuid" :courseType="courseInfo.CourseType" :unlocked="unlocked" :list="courseInfo.CourseType == 0 ? lectureList : courseGroupList" @unlock-click="onUnlockClick"></course-lecture-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="box-bottom_right">
        <school-hot></school-hot>
      </div>
    </div>
  </div>
</template>

<script>
import SchoolHot from "@/components/PC/SchoolHot";
import VideoPlayer from "@/components/PC/VideoPlayer";
import CourseLessonList from "@/components/PC/CourseLessonList";
import CourseLectureTable from "@/components/PC/CourseLectureTable";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    SchoolHot,
    VideoPlayer,
    CourseLessonList,
    CourseLectureTable,
  },
  props: {
  },
  data() {
    return {
      unlocked: null,
      courseGuid: null,
      courseInfo: null,
      activeTab: '1',
      activeLesson: null,
      lessonList: [],
      lectureList: [],
      courseGroupList: [],
    };
  },
  computed: {
    courseIntroImage() {
      if (this.courseGuid) {
        return `${Config.course.introImage}?CourseGuid=${this.courseGuid}`;
      }
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.courseGuid = this.$route.params.courseGuid;
    },
    //获取是否购买课程
    getCoursePurchase() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.purchased, model)
        .then((res) => {
          this.unlocked = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取课程信息
    getCourseInfo() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.courseInfo, model)
        .then((res) => {
          this.courseInfo = res.data.Data;
          //设置标题
          window.document.title = this.courseInfo.CourseName;
          //添加访问记录
          this.addVisitProductRecord();
          //获取课时、讲义列表
          if (this.courseInfo.CourseType == 0) {
            this.getCourseLessonList();
            this.getCourseLectureList();
          } else if (this.courseInfo.CourseType == 1) {
            this.getCourseGroupList();
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取课程组合列表
    getCourseGroupList() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.groupList, model)
        .then((res) => {
          let data = res.data.Data;
          this.courseGroupList = data.List;
          if (this.courseGroupList.length > 0 && this.courseGroupList[0].CourseLessonList.length > 0 && this.courseGroupList[0].CourseLessonList[0].LessonFreeVideo) {
            this.activeLesson = this.courseGroupList[0].CourseLessonList[0];
            this.playVideo();
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取课时列表
    getCourseLessonList() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.lessonList, model)
        .then((res) => {
          let data = res.data.Data;
          this.lessonList = data.List;
          if (this.lessonList.length > 0 && this.lessonList[0].LessonFreeVideo) {
            this.activeLesson = this.lessonList[0];
            this.playVideo();
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取讲义列表
    getCourseLectureList() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.lectureList, model)
        .then((res) => {
          let data = res.data.Data;
          this.lectureList = data.List;
          this.lectureCount = data.TotalCount;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //初始化视频
    initVideo() {
      this.$refs.video.init(null, null);
    },
    //播放视频
    playVideo() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var url = `${Config.course.lessonVideo}?CourseGuid=${this.courseGuid}&LessonGuid=${this.activeLesson.LessonGuid}&Token=${this.$store.state.token}`;
      console.log('播放地址', url);
      this.$refs.video.replay(url);
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord(this.courseInfo.CourseName);
    },
    //点击在线支付
    onPay() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "SubmitCourseOrder",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            courseGuid: this.courseGuid
          }
        });
      }
    },
    //免费试看
    onSee() {
      window.location.href = Manager.goCourseVideoUrl(this.$store.state.proxyCode, this.courseGuid, this.$store.state.token);
    },
    //联系客服
    onContact() {
      this.$router.push({ name: "Contact" });
    },
    //点击播放
    onPlayClick(item) {
      if (!this.unlocked && item.LessonFreeVideo) {
        this.activeLesson = item;
        this.playVideo();
      } else {
        window.location.href = Manager.goCourseVideoUrl(this.$store.state.proxyCode, this.courseGuid, this.$store.state.token);
      }
    },
    //点击解锁
    onUnlockClick(item) {
      this.onPay();
    },
  },
  mounted() {
    this.initVideo();
    this.getRoutParams();
    this.getCoursePurchase();
    this.getCourseInfo();
  }
};
</script>
<style lang="less" scoped>
.content-breadcrumb {
  margin-bottom: 20px;
}

.content-top {
  display: flex;
  height: 500px;

  .content-video-box {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #dcdfe6;

    .content-title {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 18px;
      background-color: #f5f7fa;
    }

    .content-video {
      flex: 1;
    }
  }

  .content-right-tabs {
    width: 300px;
    height: 100%;
    margin-left: 18px;
    border: 1px solid #dcdfe6;
    box-shadow: unset;
  }
}

.content-bottom {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .box-bottom_left {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }

  .box-bottom_right {
    width: 300px;
    margin: 0 0 0 20px;
  }
}

.course-introl-image {
  width: 100%;
}

.course-teacher-text {
  color: #555;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.tabs-box {
  position: relative;
  margin: 20px 0 0 0;

  .free {
    position: absolute;
    top: 0px;
    left: 145px;
    z-index: 100;
    background-color: red;
    color: white;
    font-size: 10px;
    padding: 1px 8px;
    border-radius: 20px;
  }
}

.info-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdfe6;
  padding: 10px;

  .info_row {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .info_margin_left {
    margin-left: 30px;
  }

  .info_img {
    width: 15px;
    padding-right: 5px;
  }

  .info_title {
    font-size: 18px;
    color: black;
  }

  .info_desc {
    font-size: 14px;
    color: gray;
    margin-right: 5px;
  }

  .info_text {
    font-size: 14px;
    color: black;
    margin-right: 20px;
  }

  .info_button_box {
    margin-left: auto;
  }

  .info_price_box {
    margin-left: auto;

    .selling-price {
      color: #ff6602;
      font-weight: bold;
      font-size: 24px;
      display: flex;
      justify-content: flex-end;
    }

    .original-price {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
      color: gray;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
}

::v-deep .el-tabs--border-card>.el-tabs__content {
  padding: 0px !important;
  height: calc(100% - 40px) !important;
  overflow-y: auto !important;
}
</style>