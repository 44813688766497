<template>
  <div>
    <div v-if="courseType == 0" class="course-box">
      <course-lecture-item v-for="item in list" :unlocked="unlocked" :lectureName="item.LectureName" @unlock-click="onUnlockClick"></course-lecture-item>
    </div>
    <div v-else-if="courseType == 1" class="course-box">
      <div v-for="courseInfo in list">
        <div class="course-name">{{ courseInfo.CourseName }}</div>
        <course-lecture-item v-for="item in courseInfo.CourseLectureList" :unlocked="unlocked" :courseGuid="courseGuid" :lectureGuid="item.LectureGuid" :lectureName="item.LectureName" @unlock-click="onUnlockClick"></course-lecture-item>
      </div>
    </div>
  </div>
</template>

<script>
import CourseLectureItem from "@/components/MT/CourseLectureItem";
export default {
  name: "CourseLectureList",
  components: {
    CourseLectureItem
  },
  props: {
    unlocked: {
      type: Boolean,
      default: false,
    },
    courseGuid: {
      type: String,
      default: null,
    },
    courseType: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onUnlockClick(item) {
      this.$emit("unlock-click", item);
    },
  },
  mounted() { },
};
</script>

<style scoped lang="less">
.course-box {
  margin-bottom: 20px;
}

.course-name {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>